html, body, #root {
  height: 100%;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* This allows the root to expand */
}

.container {
  flex-grow: 1; /* Make the content grow and take available space */
}



#particles-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1; /* Ensure it stays behind the main content */
}

#thank {
  padding: 20px; /* Optional: Styling for the thank div */
}
