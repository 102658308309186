.header {
  height: 80px;
  display: flex; /* Align items horizontally */
  align-items: center; /* Center vertically */
  justify-content: flex-start; /* Align to the left */
  padding: 5px 10px; /* Reduced padding for smaller height (adjust as needed) */
  background: rgba(255, 255, 255, 0.3); /* Glass background effect */
  backdrop-filter: blur(10px); /* Blur effect */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  position: fixed; /* Keep the header fixed at the top */
  width: 100%; /* Full width */
  z-index: 1000; /* Ensure it stays on top of other elements */
}

.logo {
  width: 120px; /* Reduced logo width */
  height: auto; /* Maintain aspect ratio */
  margin-right: 10px;
  margin-top: 30px; /* Reduced spacing between logo and title */
}

.title {
  color: #000; /* Text color for the title */
  font-size: 20px; /* Reduced font size for the title */
  font-weight: bold; /* Make the title bold */
}
